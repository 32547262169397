import type { FC } from 'react';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { LinkItem } from '@atlaskit/side-navigation';
import type { LinkItemProps } from '@atlaskit/side-navigation';
import Tooltip from '@atlaskit/tooltip';
import { SpotlightTarget } from '@atlaskit/onboarding';

import { isFedRamp } from '@atlassian/atl-context';

import { usePageSpaceKey } from '@confluence/page-context';
import { PremiumLozengeLoadable } from '@confluence/trial-components';
import { LoadableAfterPaint } from '@confluence/loadable';
import { SPACE_ANALYTICS_PLUGIN, FEDRAMP_SPACE_ANALYTICS_PLUGIN } from '@confluence/named-routes';
import {
	useTrackPremiumFeatureUsage,
	PremiumFeatureUsage,
} from '@confluence/premium-onboarding/entry-points/useTrackPremiumFeatureUsage';

import { cssFn, isRoute } from './SpaceLinks';

const GraphBarIcon = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GraphBarIcon" */ './img/GraphBarIcon'))
			.GraphBarIcon,
});

const i18n = defineMessages({
	analyticsTooltip: {
		id: 'side-navigation.space-navigation.analytics.tooltip',
		defaultMessage: 'View analytics for this space',
		description: 'Tooltip message on hover of analytics space link',
	},
	analytics: {
		id: 'side-navigation.container.analytics',
		defaultMessage: 'Analytics',
		description: 'Analytics Menu Item',
	},
});

type AnalyticsNavigationProps = Pick<LinkItemProps, 'onClick'>;

export const AnalyticsNavigation: FC<AnalyticsNavigationProps> = ({ onClick }) => {
	const [spaceKey] = usePageSpaceKey();

	const isFedrampEnv = isFedRamp();
	const isSelected = isRoute(
		location?.pathname,
		isFedrampEnv ? FEDRAMP_SPACE_ANALYTICS_PLUGIN : SPACE_ANALYTICS_PLUGIN,
	);

	const { markFeatureAsUsed } = useTrackPremiumFeatureUsage(
		PremiumFeatureUsage.ANALYTICS_VIEW_SPACE,
		/* skip */ !isSelected,
	);
	useEffect(markFeatureAsUsed, [markFeatureAsUsed]);

	return (
		<SpotlightTarget key="space-analytics" name="page-analytics-spotlight">
			<Tooltip position="top" content={<FormattedMessage {...i18n.analyticsTooltip} />}>
				{(tooltipProps) => (
					<li>
						<LinkItem
							//	 eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							key="analytics"
							iconBefore={<GraphBarIcon isSelected={isSelected} />}
							isSelected={isSelected}
							href={
								isFedrampEnv
									? FEDRAMP_SPACE_ANALYTICS_PLUGIN.toUrl({ spaceKey })
									: SPACE_ANALYTICS_PLUGIN.toUrl({ spaceKey })
							}
							{...tooltipProps}
							onClick={onClick}
							testId="space-navigation-analytics-link"
						>
							<FormattedMessage {...i18n.analytics} />
							<PremiumLozengeLoadable />
						</LinkItem>
					</li>
				)}
			</Tooltip>
		</SpotlightTarget>
	);
};
